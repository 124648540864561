import { Outlet } from 'react-router-dom';
import { ResultsLoadingBackdrop, TopNav } from 'components';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { usePageTracker } from 'components';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Auth0ContextInterface, User, useAuth0 } from '@auth0/auth0-react';

import { getResetIdleTimer, app } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { useUserStateStore } from 'zstore/userStateStore';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    content: {
      flex: '1',
      maxHeight: '100%',
      overflow: 'hidden',
    },
    container: {
      height: '100%',
      maxHeight: '100%',
    },
    header: {},
    contents: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  };
});

declare global {
  interface Window {
    accessToken: string;
  }
}

export const MainPage = () => {
  const { classes } = useStyles();
  const { logout }: Auth0ContextInterface = useAuth0();

  const dispatch = useDispatch();
  const resetIdleTimer = useSelector(getResetIdleTimer);
  usePageTracker();

  const { user: storeUser } = useUserStateStore();
  const appMetadataKey = `https://${import.meta.env.VITE_AUTH0_DOMAIN}_app_metadata`;
  const appMetadata = storeUser[appMetadataKey];

  // Log the user out if idle in all tabs after 15 minutes.
  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * 45,
    onIdle: () => {
      if (import.meta.env.PROD) {
        logout({
          clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
          logoutParams: {
            federated: true,
            returnTo: window.location.origin,
          },
        });
      }
    },
    crossTab: true,
  });

  useEffect(() => {
    if (resetIdleTimer) {
      reset();
      dispatch(app.actions.setResetIdleTimer(false));
    }
  }, [resetIdleTimer]);

  if (appMetadata?.requireLink) return null; // prevent flicker when redirecting to link accounts page
  return (
    <Grid container wrap="nowrap" direction="column">
      <ResultsLoadingBackdrop />
      <Grid item container className={classes.content}>
        <Grid container className={classes.container} direction="column" wrap="nowrap">
          <Grid item className={classes.header}>
            <TopNav />
          </Grid>
          <Outlet />
        </Grid>
      </Grid>
    </Grid>
  );
};
